"use strict";
import { jsx } from "react/jsx-runtime";
import { lazy } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Loadable from "@common/components/loadable";
import Login from "../common/pages/login/login";
import Signup from "../common/pages/login/signup";
import MultiAccount from "../common/pages/login/multi-account";
import { ProtectedRoute } from "../common/components/authentication/admin-protected";
import { PermittedRoute } from "../common/components/permissions/permittedRoute";
const SuggestionsList = Loadable(
  lazy(() => import("../administration/pages/administration/suggestions"))
);
const OrganizationsAdmin = Loadable(
  lazy(() => import("../administration/pages/administration/organizations"))
);
const UsersIndexAdmin = Loadable(
  lazy(() => import("../administration/pages/administration/organizations/users"))
);
const GroupsIndexAdmin = Loadable(
  lazy(() => import("../administration/pages/administration/organizations/groups"))
);
const PackagesAdmin = Loadable(
  lazy(() => import("../administration/pages/administration/packages"))
);
const FeaturesAdmin = Loadable(
  lazy(() => import("../administration/pages/administration/features"))
);
const SuggestionsWeights = Loadable(
  lazy(() => import("../administration/pages/administration/suggestions-weights/screens"))
);
const AudienceAdmin = Loadable(
  lazy(() => import("../administration/pages/administration/audiences"))
);
const CycleAdmin = Loadable(lazy(() => import("../administration/pages/administration/cycle")));
const CyclesPlansAdmin = Loadable(
  lazy(() => import("../administration/pages/administration/cycles-plans"))
);
const AdminCyclesPlansTargets = Loadable(
  lazy(() => import("../administration/pages/administration/cycles-plans-targets"))
);
const Home = Loadable(lazy(() => import("../adminHome")));
const router = createBrowserRouter([
  {
    path: "/login",
    element: /* @__PURE__ */ jsx(Login, { mode: "web", callback: () => ({}) })
  },
  {
    path: "/signup",
    element: /* @__PURE__ */ jsx(Signup, { mode: "web", callback: () => ({}) })
  },
  {
    path: "/my/accounts",
    element: /* @__PURE__ */ jsx(MultiAccount, {})
  },
  {
    path: "/",
    element: /* @__PURE__ */ jsx(ProtectedRoute, { children: /* @__PURE__ */ jsx(PermittedRoute, { accessRules: { roles: ["global_admin"] }, children: /* @__PURE__ */ jsx(Home, {}) }) }),
    children: [
      {
        index: true,
        element: /* @__PURE__ */ jsx(PackagesAdmin, {})
      },
      {
        path: "admin",
        children: [
          {
            path: "packages",
            element: /* @__PURE__ */ jsx(PackagesAdmin, {})
          },
          {
            path: "features",
            element: /* @__PURE__ */ jsx(FeaturesAdmin, {})
          },
          {
            path: "organizations",
            element: /* @__PURE__ */ jsx(OrganizationsAdmin, {})
          },
          {
            path: "users",
            element: /* @__PURE__ */ jsx(UsersIndexAdmin, {})
          },
          {
            path: "groups",
            element: /* @__PURE__ */ jsx(GroupsIndexAdmin, {})
          },
          {
            path: "suggestions/suggestions-weights",
            element: /* @__PURE__ */ jsx(SuggestionsWeights, {})
          },
          {
            path: "suggestions/list",
            element: /* @__PURE__ */ jsx(SuggestionsList, {})
          },
          {
            path: "audiences",
            element: /* @__PURE__ */ jsx(AudienceAdmin, {})
          },
          {
            path: "veeva/cycle",
            element: /* @__PURE__ */ jsx(CycleAdmin, {})
          },
          {
            path: "veeva/cycles-plans",
            element: /* @__PURE__ */ jsx(CyclesPlansAdmin, {})
          },
          {
            path: "veeva/cycle-plans-targets",
            element: /* @__PURE__ */ jsx(AdminCyclesPlansTargets, {})
          }
        ]
      }
    ]
  },
  {
    path: "*",
    element: /* @__PURE__ */ jsx(Navigate, { to: "/login" })
  }
]);
export default router;
